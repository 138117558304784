@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('./css/fontawesome-6.4.0-all.min.css');
@import url('./css/fontawesome-custom-icons.css');

* {
	margin: 0;
	padding: 0;
	font-family: 'Inter', sans-serif;
}

.lds-ripple {
	display: inline-block;
	position: relative;
	width: 80px;
	height: 80px;
}
.lds-ripple div {
	position: absolute;
	border: 4px solid #000;
	opacity: 1;
	border-radius: 50%;
	animation: lds-ripple 1s cubic-bezier(0, 0.2, 0.8, 1) infinite;
}
.lds-ripple div:nth-child(2) {
	animation-delay: -0.5s;
}

.mobile-h-full {
	height: 100%;
	height: -webkit-fill-available;
	height: stretch;
}

@keyframes lds-ripple {
	0% {
		top: 36px;
		left: 36px;
		width: 0;
		height: 0;
		opacity: 0;
	}
	4.9% {
		top: 36px;
		left: 36px;
		width: 0;
		height: 0;
		opacity: 0;
	}
	5% {
		top: 36px;
		left: 36px;
		width: 0;
		height: 0;
		opacity: 1;
	}
	100% {
		top: 0px;
		left: 0px;
		width: 72px;
		height: 72px;
		opacity: 0;
	}
}

.black-button {
	@apply rounded-lg border border-transparent px-4 py-2 text-base font-medium bg-black text-white shadow-sm hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-gray-700 focus:ring-offset-2 sm:col-start-2 sm:text-lg;
}

.black-button-xl {
	@apply rounded-lg border border-transparent px-8 py-2 text-base font-medium bg-black text-white shadow-sm hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-gray-700 focus:ring-offset-2 sm:col-start-2 sm:text-2xl;
}

.gray-button {
	@apply rounded-lg border border-transparent px-4 py-2 text-base font-medium bg-gray-300 text-gray-700 shadow-sm hover:bg-gray-200 focus:outline-none focus:ring-2 focus:ring-gray-700 focus:ring-offset-2 sm:col-start-2 sm:text-lg;
}

.react-datepicker__input-container input {
	@apply block w-full text-base md:text-sm bg-white border border-gray-300 rounded shadow-sm form-input;
}

.react-datepicker-popper {
	@apply z-40 w-72 text-sm bg-white shadow px-3 py-2 border-2 border-gray-200 rounded;
}

.react-datepicker-left {
	@apply absolute left-0 right-auto top-11 transform-none !important;
}

.react-datepicker-right {
	@apply absolute right-0 left-auto top-11 transform-none !important;
}

.react-datepicker__portal {
	@apply absolute z-10 w-72 text-sm transform-none bg-white shadow px-3 py-2 top-12 right-0 border-2 border-gray-200 rounded;
}

.react-datepicker__month-container {
	@apply flex flex-col;
}

.react-datepicker__month {
	@apply flex flex-col;
}

.react-datepicker__current-month {
	@apply text-center text-lg font-semibold text-gray-800;
}

.react-datepicker__aria-live {
	@apply hidden;
}

.react-datepicker__week {
	@apply flex justify-around;
}

.react-datepicker__day {
	@apply cursor-pointer hover:bg-glazier-blue-400;
}

.react-datepicker__day-names {
	@apply flex justify-around text-gray-400 font-medium text-center text-xs;
}

.react-datepicker__day-name {
	@apply w-8 h-8 flex items-center justify-center py-1 rounded-full;
}

.react-datepicker__navigation {
	@apply absolute top-2;
}

.react-datepicker__navigation--previous {
	@apply left-4 w-8 h-8 rounded transition flex items-center justify-center hover:bg-gray-200;
}

.react-datepicker__navigation--next {
	@apply right-4 w-8 h-8 rounded transition flex items-center justify-center hover:bg-gray-200;
}

.react-datepicker__day {
	@apply mb-1 w-8 h-8 flex items-center justify-center py-1 text-sm leading-loose transition text-gray-700 rounded;
}

.react-datepicker__day--disabled {
	@apply cursor-not-allowed opacity-40 hover:bg-transparent;
}

.react-datepicker__day--outside-month {
	@apply text-gray-300;
}

.react-datepicker__day--in-range {
	@apply bg-gray-200;
}

.react-datepicker__day--in-selecting-range {
	@apply bg-blue-200;
}

.react-datepicker__day--selecting-range-start {
	@apply bg-white border-2 border-blue-500;
}

.react-datepicker__day--selecting-range-end {
	@apply bg-white border-2 border-blue-500;
}

.react-datepicker__day--selected {
	@apply bg-glazier-blue-700 text-black;
}

.react-datepicker__day--range-start {
	@apply bg-blue-500 text-white hover:text-gray-700 hover:bg-white;
}

.react-datepicker__day--range-end {
	@apply bg-blue-500 text-white hover:text-gray-700 hover:bg-white;
}

.react-datepicker__navigation-icon:hover {
	background-color: #e5e5e5;
}

.glazier-blue-gradient {
	@apply from-glazier-blue-200 to-glazier-blue-700;
}

.dark-glazier-blue-gradient {
	@apply from-glazier-blue-400 to-glazier-blue-900;
}

.glazier-navy-gradient {
	@apply from-glazier-navy-500 to-glazier-navy-900;
}

.cl-user-button-fullname {
	@apply text-gray-100 font-bold;
}

.mapboxgl-ctrl-logo {
	display: none !important;
}

.mapboxgl-popup-tip {
	border-left-color: #1a262d !important;
}

.mapboxgl-popup-content {
	padding: 0px !important;
	background: #1a262d !important;
}

.Toastify__toast-icon {
	padding-top: 5px;
	margin-right: 6px;
	margin-left: 3px;
}

:root {
	--toastify-icon-color-error: #84006a;
	--toastify-z-index: 9999;
}

.editable-container:hover > .show-on-container-hover {
	display: inline;
}

.show-on-container-hover {
	display: none;
}

.po-column-header {
	@apply px-3 py-3.5 text-left text-lg font-semibold text-gray-900;
}

.fab-cell-padding {
	@apply px-2 py-3;
}

.fab-col-header-text {
	@apply text-sm font-semibold text-gray-900;
}

.save-pulse {
	/*box-shadow: 0 0 0 20px rgba(229,62,62, 0.5);
   transform: scale(0.8);*/
	animation: save-pulse 2.5s infinite;
}

@keyframes save-pulse {
	0% {
		transform: scale(0.95);
		box-shadow: 0 0 0 0 rgba(16, 185, 129, 1);
	}

	70% {
		transform: scale(1);
		box-shadow: 0 0 0 15px rgba(20, 83, 45, 0);
	}

	100% {
		transform: scale(0.95);
	}
}

@keyframes partial-ping {
	75%,
	100% {
		transform: scale(2);
		opacity: 0.33;
	}
}
.animate-slow-ping {
	animation: partial-ping 1.25s cubic-bezier(0, 0, 0.2, 1) infinite;
}

.standard-button {
	@apply text-white hover:bg-primary-dark bg-primary border-2 px-4 py-2 inline-flex items-center rounded-md font-medium;
}

.responsive-stacking-buttons {
	@apply flex flex-col items-center space-y-4 lg:flex-row lg:space-y-0 lg:space-x-3;
}

.change-order-th {
	@apply py-3 sm:pl-0 text-xs xl:text-sm font-medium uppercase tracking-wide text-gray-500;
}

.gray-bg {
	@apply bg-gray-200;
}

.gray-border {
	@apply border-gray-300;
}

.iframe-header-height {
	/* chrome iframe height */
	height: 57px;
}

.delete-button {
	@apply text-red-500 hover:text-red-700 cursor-pointer inline-flex;
}

.paid-colors {
	@apply bg-green-300 text-green-800;
}

.awaiting-colors {
	@apply bg-orange-300 text-orange-800;
}

.pending-colors {
	@apply bg-yellow-300 text-yellow-800;
}

.table-hover tbody tr:hover td,
.table-hover tbody tr:hover th {
	@apply gray-bg;
}

.task-card {
	@apply w-full rounded-md bg-white p-4 shadow-md;
}

.GlassDiagramDetails:hover {
	cursor: ew-resize;
}

.GlassDiagramDetails .glassBuilderPanel:hover {
	cursor: pointer;
	fill: rgba(201, 215, 221, 50%);
}

.ag-details-row {
	padding: 5px 5px 5px 5px !important;
}

.double-tall-events .mbsc-calendar-labels .mbsc-calendar-custom-label.mbsc-calendar-text {
	height: 3.6em;
}

.mbsc-schedule-event-all-day {
	padding-left: 0px !important;
}
