.fak.fa-level-bottom-left::before,
.fa-kit.fa-level-bottom-left::before {
	content: '\e002';
}
.fak.fa-level-bottom-right::before,
.fa-kit.fa-level-bottom-right::before {
	content: '\e003';
}
.fak.fa-level-top-left::before,
.fa-kit.fa-level-top-left::before {
	content: '\e004';
}
.fak.fa-level-top-right::before,
.fa-kit.fa-level-top-right::before {
	content: '\e005';
}
.fak.fa-plumb-bottom-left::before,
.fa-kit.fa-plumb-bottom-left::before {
	content: '\e008';
}
.fak.fa-plumb-bottom-right::before,
.fa-kit.fa-plumb-bottom-right::before {
	content: '\e009';
}
.fak.fa-plumb-top-left::before,
.fa-kit.fa-plumb-top-left::before {
	content: '\e007';
}
.fak.fa-plumb-top-right::before,
.fa-kit.fa-plumb-top-right::before {
	content: '\e006';
}
.fak.fa-quickbooks::before,
.fa-kit.fa-quickbooks::before {
	content: '\e000';
}
.fak.fa-quickbooks-light::before,
.fa-kit.fa-quickbooks-light::before {
	content: '\e001';
}

.fak,
.fa-kit {
	-moz-osx-font-smoothing: grayscale;
	-webkit-font-smoothing: antialiased;
	display: var(--fa-display, inline-block);
	font-style: normal;
	font-variant: normal;
	text-rendering: auto;
	line-height: 1;
	font-family: 'Font Awesome Kit';
}

@font-face {
	font-family: 'Font Awesome Kit';
	font-style: normal;
	font-display: block;
	src:
		url('../webfonts/custom-icons.woff2') format('woff2'),
		url('../webfonts/custom-icons.ttf') format('truetype');
}
